.videosGrid{
    display: grid;
    grid-template-columns: repeat(auto-fill, 400px);
    grid-template-rows: 230px 230px 230px 230px 230px 230px 230px 230px 230px;
    justify-content: center;
    padding: 10px;
    text-decoration: none;
}

@media (max-width: 950px){
    .videosGrid{
       grid-template-columns: 100%;
    }
}