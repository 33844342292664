.containerAbout{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.col{
    max-width: 400px;
    margin: 25px;
}

.imageAbout{
    border-radius: 5%;
}

.textAbout{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400; 
    color: var(--text-color);
}

.linkMagazine{
    color: var(--text-color);
}

.linkMagazine:hover{
    color: var(--secundary-color);
}

@media (min-width: 950px){
    .textAbout{
        font-size: 1rem;
    }
}