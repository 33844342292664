body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--theme-color);
}
:root{
  --theme-color: rgb(0, 0, 0);
  --primary-color: #171746;
  --secundary-color: #5790be;
  --input-color:#ffffff;
  --text-color-inverse: rgb(0, 0, 0);
  --text-color: white;
  --navbar-shallow-color: #e5e5e5;
  --menu-mobile-background-color: #20381e;
 }

