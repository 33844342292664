body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 0, 0);
  background-color: var(--theme-color);
}
:root{
  --theme-color: rgb(0, 0, 0);
  --primary-color: #171746;
  --secundary-color: #5790be;
  --input-color:#ffffff;
  --text-color-inverse: rgb(0, 0, 0);
  --text-color: white;
  --navbar-shallow-color: #e5e5e5;
  --menu-mobile-background-color: #20381e;
 }


.App_artistName__24CY1{
    display: flex;
    justify-content: center;
    font-family: 'Allerta Stencil', sans-serif;
    font-size: 1.85em;
    color: var(--text-color);
    padding-top: 0.7em;
    padding-bottom: 0.5em;
}

.App_artistName__24CY1:hover{
    color: var(--secundary-color);
}

.App_primaryLink__R1u7s{
    text-decoration: none;
}

.App_menuLine__2zUc9{
    height: 1px;
    width: 25em;
    background-color: white;
    opacity: 0.2;
}

.App_footer__136r8{
    text-align: center;
    font-size: 0.73em;
}

.App_linkFooter__-vPbd{
    text-decoration: none;
    color: var(--text-color);
    opacity: 0.5;
    padding: 5px;
}

.App_linkFooter__-vPbd:hover{
    color: var(--secundary-color);
}

@media (min-width: 950px){
    .App_artistName__24CY1{
        font-size: 3em;  
    }
}
.Menu_topNav__2-uCU {
    display: flex;
    justify-content: center;
    padding: 1px;
    max-width: 980px;
    margin: 0 auto;
  }

.Menu_menu__aym5_{
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.Menu_menu__aym5_ li {
    padding: 0 15px;
    
}

.Menu_linkMenu__NmzxX{
    text-decoration: none;
    color: var(--text-color);
    font-size: 13.2px;
    font-weight: 400;
    line-height: 30px;
    text-transform: uppercase;
    position: relative;
}


.Menu_linkMenu__NmzxX:hover{
    color: var(--secundary-color);
}

.Menu_openMenu__2QDcp,
.Menu_closeMenu__iTEFr {
    display: none;
}
  
@media (min-width: 950px){
    .Menu_linkMenu__NmzxX{
        font-size: 18px;  
    }
}
.AboutMe_containerAbout__vS2LS{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.AboutMe_col__1l3j0{
    max-width: 400px;
    margin: 25px;
}

.AboutMe_imageAbout__2ORO2{
    border-radius: 5%;
}

.AboutMe_textAbout__2hKPs{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400; 
    color: var(--text-color);
}

.AboutMe_linkMagazine__3wbQO{
    color: var(--text-color);
}

.AboutMe_linkMagazine__3wbQO:hover{
    color: var(--secundary-color);
}

@media (min-width: 950px){
    .AboutMe_textAbout__2hKPs{
        font-size: 1rem;
    }
}
.VideosCard_videoCard__2Q1mR{
    list-style: none;
    font-size: 0.9em;
    text-align: center;
    padding-left: 10px;
    text-decoration: none;
    color: var(--text-color);
}


.VideosCard_videoCard__2Q1mR:hover{
    opacity: 0.7;
}

.VideosCard_videoCard__2Q1mR:visited{
    color: var(--text-color);
}

.VideosCard_letterHover__2Wbeg:hover{
    color: var(--secundary-color);
}

.VideosCard_videoImage__3dbVl{
    border-radius: 10px;
    background-color: azure;
}

.VideosCard_videoTitle__3llZL{
    text-align: center;
}

@media (min-width: 950px){
    .VideosCard_videoCard__2Q1mR{
        width: 90%;
        height: auto;
        font-size: 1em;
    }
}
.VideosGrid_videosGrid__1idMp{
    display: grid;
    grid-template-columns: repeat(auto-fill, 400px);
    grid-template-rows: 230px 230px 230px 230px 230px 230px 230px 230px 230px;
    justify-content: center;
    padding: 10px;
    text-decoration: none;
}

@media (max-width: 950px){
    .VideosGrid_videosGrid__1idMp{
       grid-template-columns: 100%;
    }
}
.VideoWorks_titleVideoGrid__17qug{
    text-decoration: none;
}


.Contact_form__-UcDo{
    display: flex;
    flex-direction: column;
}

.Contact_contactContent__s_AUR{
    display: flex;
    justify-content: center; 
    margin-top: 20px;   
}

.Contact_contactLabelName__3TGtu, .Contact_contactLabelEmail__2cUfk, 
.Contact_contactLabelTextArea__3Htxd{
    color: var(--text-color);
    font-size: 15px;  
}

.Contact_contactInputName__1P6gH{
    background-color: var(--input-color);
    border-radius: 7px;  
    border: none;
    margin-top: 5px;
    font-size: 15px;  
}

.Contact_contactInputEmail__12Bo0{
    background-color: var(--input-color);
    border-radius: 7px;  
    border: none;
    margin-top: 5px;
    font-size: 15px;   
}

.Contact_contactInputTextArea__1ci0w{
    background-color: var(--input-color);
    border-radius: 7px;  
    border: none; 
    margin-top: 7px;
    font-size: 15px; 
}

.Contact_contactMensajeCaptcha__kYOqg{
    color: red;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin: 6px;
}

.Contact_contactBtn__cnHim {
    background-color: var(--secundary-color);
    color: var(--primary-color);
    font-weight: bold;
    border: none;
    font-size: 15px;
    padding: 0.5em 1em;
    border-radius: 7px;
    cursor: pointer;
    max-width: 300px;
    margin: 0 auto; 
}

.Contact_contactBtn__cnHim:hover {
    -webkit-filter: brightness(130%);
            filter: brightness(130%);
}

@media (min-width: 950px){
    .Contact_contactLabelName__3TGtu, .Contact_contactLabelEmail__2cUfk, 
    .Contact_contactLabelTextArea__3Htxd{
        color: var(--text-color);
        font-size: 18px;  
    }
    .Contact_contactInputName__1P6gH{
        font-size: 18px;  
    }
    .Contact_contactInputEmail__12Bo0{
        font-size: 18px; 
    }
    .Contact_contactInputTextArea__1ci0w{
        font-size: 18px; 
    }
    .Contact_contactBtn__cnHim {
        font-size: 18px; 
    }
}
.LandingPage_imageContainer__2gQ4z{
    display: flex;
    justify-content: center;
    padding-top: 2em;
    border-radius: 4.5rem;
    padding-bottom: 15em;
}

.LandingPage_videoCover__QYEtN{
    border-radius: 0.6rem;
    width: 20rem;
    height: 13rem;
    object-fit: cover;
}

@media (min-width: 950px){
    .LandingPage_videoCover__QYEtN{
        border-radius: 0.7rem;
        width: 32rem;
        height: 20rem; 
    }
    .LandingPage_imageContainer__2gQ4z{
        padding-bottom: 3em;   
    }
}
.VideoDetails_detailsContainer__1t1SV{
    display: flex;
    justify-content: center;
    color: var(--text-color);
}

.VideoDetails_videoContainer__2w2a9{
    border-radius: 15px;
    text-decoration: none;
    height: 200px;
    width: 380px;
}

.VideoDetails_textDetails__1J5cI{
    font-size: 15px;
}

.VideoDetails_videoBack__3rSiP{
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    text-transform: uppercase;
    position: relative;
    text-align: end;
}

.VideoDetails_videoBack__3rSiP:hover{
    color: var(--secundary-color);
}

@media (min-width: 950px){
    .VideoDetails_videoContainer__2w2a9{
        height: 315px;
        width: 560px;
    }
    .VideoDetails_textDetails__1J5cI{
        font-size: 17px;
    }
    .VideoDetails_videoBack__3rSiP{
    font-size: 18px;
    }
}
