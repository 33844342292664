.form{
    display: flex;
    flex-direction: column;
}

.contactContent{
    display: flex;
    justify-content: center; 
    margin-top: 20px;   
}

.contactLabelName, .contactLabelEmail, 
.contactLabelTextArea{
    color: var(--text-color);
    font-size: 15px;  
}

.contactInputName{
    background-color: var(--input-color);
    border-radius: 7px;  
    border: none;
    margin-top: 5px;
    font-size: 15px;  
}

.contactInputEmail{
    background-color: var(--input-color);
    border-radius: 7px;  
    border: none;
    margin-top: 5px;
    font-size: 15px;   
}

.contactInputTextArea{
    background-color: var(--input-color);
    border-radius: 7px;  
    border: none; 
    margin-top: 7px;
    font-size: 15px; 
}

.contactMensajeCaptcha{
    color: red;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin: 6px;
}

.contactBtn {
    background-color: var(--secundary-color);
    color: var(--primary-color);
    font-weight: bold;
    border: none;
    font-size: 15px;
    padding: 0.5em 1em;
    border-radius: 7px;
    cursor: pointer;
    max-width: 300px;
    margin: 0 auto; 
}

.contactBtn:hover {
    filter: brightness(130%);
}

@media (min-width: 950px){
    .contactLabelName, .contactLabelEmail, 
    .contactLabelTextArea{
        color: var(--text-color);
        font-size: 18px;  
    }
    .contactInputName{
        font-size: 18px;  
    }
    .contactInputEmail{
        font-size: 18px; 
    }
    .contactInputTextArea{
        font-size: 18px; 
    }
    .contactBtn {
        font-size: 18px; 
    }
}