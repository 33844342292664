.topNav {
    display: flex;
    justify-content: center;
    padding: 1px;
    max-width: 980px;
    margin: 0 auto;
  }

.menu{
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu li {
    padding: 0 15px;
    
}

.linkMenu{
    text-decoration: none;
    color: var(--text-color);
    font-size: 13.2px;
    font-weight: 400;
    line-height: 30px;
    text-transform: uppercase;
    position: relative;
}


.linkMenu:hover{
    color: var(--secundary-color);
}

.openMenu,
.closeMenu {
    display: none;
}
  
@media (min-width: 950px){
    .linkMenu{
        font-size: 18px;  
    }
}