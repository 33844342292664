.videoCard{
    list-style: none;
    font-size: 0.9em;
    text-align: center;
    padding-left: 10px;
    text-decoration: none;
    color: var(--text-color);
}


.videoCard:hover{
    opacity: 0.7;
}

.videoCard:visited{
    color: var(--text-color);
}

.letterHover:hover{
    color: var(--secundary-color);
}

.videoImage{
    border-radius: 10px;
    background-color: azure;
}

.videoTitle{
    text-align: center;
}

@media (min-width: 950px){
    .videoCard{
        width: 90%;
        height: auto;
        font-size: 1em;
    }
}