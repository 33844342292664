.artistName{
    display: flex;
    justify-content: center;
    font-family: 'Allerta Stencil', sans-serif;
    font-size: 1.85em;
    color: var(--text-color);
    padding-top: 0.7em;
    padding-bottom: 0.5em;
}

.artistName:hover{
    color: var(--secundary-color);
}

.primaryLink{
    text-decoration: none;
}

.menuLine{
    height: 1px;
    width: 25em;
    background-color: white;
    opacity: 0.2;
}

.footer{
    text-align: center;
    font-size: 0.73em;
}

.linkFooter{
    text-decoration: none;
    color: var(--text-color);
    opacity: 0.5;
    padding: 5px;
}

.linkFooter:hover{
    color: var(--secundary-color);
}

@media (min-width: 950px){
    .artistName{
        font-size: 3em;  
    }
}