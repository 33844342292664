.detailsContainer{
    display: flex;
    justify-content: center;
    color: var(--text-color);
}

.videoContainer{
    border-radius: 15px;
    text-decoration: none;
    height: 200px;
    width: 380px;
}

.textDetails{
    font-size: 15px;
}

.videoBack{
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    text-transform: uppercase;
    position: relative;
    text-align: end;
}

.videoBack:hover{
    color: var(--secundary-color);
}

@media (min-width: 950px){
    .videoContainer{
        height: 315px;
        width: 560px;
    }
    .textDetails{
        font-size: 17px;
    }
    .videoBack{
    font-size: 18px;
    }
}