.imageContainer{
    display: flex;
    justify-content: center;
    padding-top: 2em;
    border-radius: 4.5rem;
    padding-bottom: 15em;
}

.videoCover{
    border-radius: 0.6rem;
    width: 20rem;
    height: 13rem;
    object-fit: cover;
}

@media (min-width: 950px){
    .videoCover{
        border-radius: 0.7rem;
        width: 32rem;
        height: 20rem; 
    }
    .imageContainer{
        padding-bottom: 3em;   
    }
}